<template>
  <div>
    <b-card>
      <b-row v-if="isAdmin">
        <b-col class="d-flex justify-content-end mb-2">
          <b-button
            variant="primary"
            @click="$router.push({ name: 'schools-create' })"
            class="d-flex align-items-center">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="text-nowrap">Nuevo</span>
          </b-button>
        </b-col>
      </b-row>

      <b-overlay
        :show="loading"
        rounded
        responsive
        variant="transparent"
        opacity="0.33"
        class="table-responsive"
        blur="2px">
        <b-table
          hover
          :items="items.data"
          primary-key="id"
          :fields="fields"
          show-empty
          empty-text="No hay colegios registrados">
          
          <template #cell(logo)="data">
            <b-avatar
              v-if="!data.item.logo"
              ref="previewEl"
              :src="data.item.logo"
              :text="avatarText(data.item.name)"
              size="70px"
              rounded/>
              <img v-if="data.item.logo"
                :src="data.item.logo"
                class="img-fluid"
                style="max-width: 70px;"/>
          </template>

          <template #cell(name)="data">
            <b-link :to="{ name: 'schools-view', params: { id: data.item.id } }" class="text-nowrap">
              {{ data.item.name }}  
            </b-link>
          </template>

          <template #cell(created_at)="data">
            {{ data.item.created_at | formatDateTimeHumane }}
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="$router.push({name: 'schools-edit', params:{id: data.item.id}})">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteSchool(data.item)"> 
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Eliminar</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-overlay>
      <section>
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="items.current_page"
              :total-rows="items.total"
              :per-page="items.per_page"
              first-number
              @change="getItems"
              align="right"
              last-number
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BTable,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BLink,
  BPagination
} from "bootstrap-vue";

import store from "@/store";
import { formatDateTimeHumane } from "@core/utils/filter.js";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BAvatar,
    BTable,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BLink,
    BPagination
  },
  filters: {
    formatDateTimeHumane,
  },
  data() {
    return {
      loading: false,
      items: {},
    }
  },
  computed: {
      isAdmin() {
        return this.$store.getters['user/userData'].roles.find(role => role.name == 'admin')
      },
    fields() {
      let data = [
        { key: 'logo', label: 'Logo', sortable: true },
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'description', label: 'Descripción', sortable: true },
        { key: 'created_at' , label: 'Creado', sortable: true },
      ]
      
      if(this.isAdmin) {
        data.push({ key: 'actions', label: 'Acciones' })
      }

      return data
    }
  },
  methods: {
    async getItems(page = 1) {
      try {
        this.loading = true
        this.items = await store.dispatch('schools/index', {page: page, search: this.search})
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async deleteSchool({ id, name }) {
     try {
        const res = await this.$bvModal.msgBoxConfirm(`Estas seguro de borrar el colegio ${name}?`, {
            title: 'Por favor confirmar',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Si',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
        if (res) {
            await store.dispatch("schools/delete", id)
            this.getItems()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Éxito',
                icon: 'TashIcon',
                variant: 'success',
                text: 'Colegio eliminado correctamente',
              },
            })
        } else {
          console.log('Cancelado')
        }
       
     } catch (error) {
       console.log(error);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'AlertCircleIcon',
          variant: 'danger',
          text: 'No se pudo eliminar el colegio, puede que este siendo usado.',
        },
      })
     }
    },
    avatarText(fullName) {
      return avatarText(fullName)
    },
  },
  mounted() {
    this.getItems()
  }
};
</script>